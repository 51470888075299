import Marquee from "react-fast-marquee";

import { useContentResource, useImageSlider } from "@ctv/core";
import {
  Image,
  TextSeo,
  ViewSeo,
  useInitialRenderState,
} from "@ctv/shared-core/src";

import styles from "./CompanyMarquee.module.css";

export default function CompanyMarquee() {
  const cr = useContentResource().CorporateRevampHomePage;
  const slider = useImageSlider().CorporateRevampHomePage;

  const rendered = useInitialRenderState();

  return (
    <ViewSeo className={styles.container} spacing="xl" align="center">
      <TextSeo className={styles.title} variant="title-2">
        {cr.marqueeText}
      </TextSeo>
      {rendered ? (
        // Marquee doesn't render children on initial render
        <Marquee autoFill>
          {slider
            .filter((data) => data.groupName === "partnerLogo")
            .map((data) => {
              return (
                <Image
                  className={styles.image}
                  key={data.link}
                  alt={data.altText}
                  src={data.link}
                  height={42}
                />
              );
            })}
        </Marquee>
      ) : (
        // For initial render, render all images at once without animation
        <ViewSeo className={styles.initialImages}>
          {slider
            .filter((data) => data.groupName === "partnerLogo")
            .map((data) => {
              return (
                <Image
                  className={styles.image}
                  key={data.link}
                  alt={data.altText}
                  src={data.link}
                  height={42}
                />
              );
            })}
        </ViewSeo>
      )}
    </ViewSeo>
  );
}
