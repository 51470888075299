import { useContentResource } from "@ctv/core";
import { GetArticleCategoryResponse } from "@ctv/core/cnt/conan/api";
import { ButtonSeo, TextSeo, ViewSeo } from "@ctv/shared-core/src";
import TrackingLink from "@ctv/shared/components/Tracking/TrackingLink";
import {
  event,
  landingPageTracking as tracking,
} from "@ctv/shared/tracking/landing-page";
import {
  trackBrowseMoreArticleButtonClick,
  trackReadArticleButtonClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";
import { UrsulaTrackingSensor as Sensor } from "@ctv/shared/tracking/landing-page/sensor";

import ConanArticleItem from "./ConanArticleItem";

import styles from "./ConanArticles.module.css";

type Props = {
  recentArticles: GetArticleCategoryResponse | null;
};

export default function ConanArticles(props: Props) {
  const { recentArticles } = props;

  const cr = useContentResource().CorporateRevampHomePage;

  if (recentArticles === null || recentArticles.total === 0) {
    return null;
  }

  return (
    <Sensor data={tracking.ARTICLE_SECTION} event={event.CORPORATE_TRAVEL}>
      <ViewSeo className={styles.container} spacing="l" align="center">
        <TextSeo variant="headline">{cr.articlesTitle}</TextSeo>
        <ViewSeo className={styles.itemsContainer}>
          <ViewSeo row spacing="m">
            <ViewSeo /> {/* Expected for additional spacing on the left */}
            {recentArticles.articles.map((article, articleOrder) => (
              <ViewSeo className={styles.articleItem}>
                <ConanArticleItem
                  article={article}
                  trackReadMoreButtonClick={trackReadArticleButtonClick(
                    article.title,
                    article.publishedDate,
                    articleOrder
                  )}
                />
              </ViewSeo>
            ))}
            <ViewSeo /> {/* Expected for additional spacing on the right */}
          </ViewSeo>
        </ViewSeo>
        <TrackingLink
          href="/articles?page=1"
          track={trackBrowseMoreArticleButtonClick}
        >
          <ButtonSeo
            text={cr.articlesBrowseMoreButtonText}
            variant="outline-primary"
          />
        </TrackingLink>
      </ViewSeo>
    </Sensor>
  );
}
