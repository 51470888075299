import { useState } from "react";
import { TouchableOpacity } from "react-native";

import { useContentResource, useImageResource } from "@ctv/core";
import { Image, Modal, TextSeo, ViewSeo } from "@ctv/shared-core/src";

import styles from "./BusinessNumber.module.css";

export default function BusinessNumber() {
  const [visible, setVisible] = useState(false);

  const cr = useContentResource().CorporateRevampBusinessNumberSection;
  const images = useImageResource().CorporateRevampHomePage;
  const contents = [
    [cr.companyNumberTitle, cr.companyNumberSubtitle],
    [cr.industryNumberTitle, cr.industryNumberSubtitle],
    [cr.tripNumberTitle, cr.tripNumberSubtitle],
  ];

  return (
    <>
      <ViewSeo className={styles.container}>
        <Image
          alt="Blue Background"
          src="/images/background-business-number-mobile.png"
          className={styles.background}
          height={847}
        />
        <ViewSeo spacing="xxl">
          <TextSeo
            className={styles.textSection}
            variant="display"
            ink="white-primary"
          >
            {cr.title}
          </TextSeo>
          <TouchableOpacity onPress={() => setVisible(true)}>
            <Image
              alt={images.videoThumbnail.altText}
              src={images.videoThumbnail.link}
              width="100%"
            />
          </TouchableOpacity>
          <ViewSeo className={styles.textSection} spacing="l">
            {contents.map(([title, subtitle]) => (
              <ViewSeo key={title} spacing="m">
                <TextSeo variant="title-1" ink="white-primary">
                  {title}
                </TextSeo>
                <TextSeo variant="ui-small" ink="white-secondary">
                  {subtitle}
                </TextSeo>
              </ViewSeo>
            ))}
          </ViewSeo>
        </ViewSeo>
      </ViewSeo>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        render={() => (
          <iframe
            frameBorder={0}
            src={cr.youtubeEmbedSrc}
            width="95%"
            allow="fullscreen"
          />
        )}
      />
    </>
  );
}
