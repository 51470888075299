import { useImageResource } from "@ctv/core";

import { Image, ViewSeo } from "@ctv/shared-core/src";
import { PropsWithChildren } from "react";
import cssStyles from "./Hero.module.css";

export default function Hero(props: PropsWithChildren) {
  const { children } = props;
  const images = useImageResource().CorporateRevampHomePage;

  return (
    <ViewSeo className={cssStyles.container}>
      <Image
        alt={images.hero.altText}
        src={images.hero.link}
        className={cssStyles.image}
      />
      {children}
    </ViewSeo>
  );
}
