import htmr from "htmr";
import Link from "next/link";

import { useContentResource } from "@ctv/core";
import { GetArticleCategoryResponse } from "@ctv/core/cnt/conan/api";
import {
  ButtonSeo,
  CardSeo,
  Image,
  TextSeo,
  useLocalizedDateFormat,
  ViewSeo,
} from "@ctv/shared-core/src";
import TrackingButtonSeo from "@ctv/shared/components/Tracking/TrackingButtonSeo";
import TrackingLink from "@ctv/shared/components/Tracking/TrackingLink";
import {
  event,
  landingPageTracking as tracking,
} from "@ctv/shared/tracking/landing-page";
import {
  trackBrowseMoreArticleButtonClick,
  trackReadArticleButtonClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";
import { UrsulaTrackingSensor as Sensor } from "@ctv/shared/tracking/landing-page/sensor";

import styles from "./ConanArticles.module.css";

type Props = {
  recentArticles: GetArticleCategoryResponse | null;
};

export default function ConanArticles(props: Props) {
  const { recentArticles } = props;

  const cr = useContentResource().CorporateRevampHomePage;
  const { format } = useLocalizedDateFormat();

  if (recentArticles === null || recentArticles.total === 0) {
    return null;
  }

  return (
    <Sensor data={tracking.ARTICLE_SECTION} event={event.CORPORATE_TRAVEL}>
      <ViewSeo className={styles.container} align="center">
        <TextSeo className={styles.title} variant="display">
          {cr.articlesTitle}
        </TextSeo>
        <ViewSeo row spacing="xl">
          {recentArticles.articles.map((article, articleOrder) => (
            <CardSeo
              key={article.url}
              className={styles.cardContainer}
              spacing="s"
            >
              <Image
                src={article.image}
                alt={article.tags.join(" ")}
                width="100%"
                height={200}
              />
              <ViewSeo className={styles.cardContent} spacing="s">
                <TextSeo variant="title-1">{article.title}</TextSeo>
                <TextSeo
                  className={styles.articleSummary}
                  ink="black-secondary"
                >
                  {htmr(article.summary)}
                </TextSeo>
                <TextSeo className={styles.articleTags} ink="black-secondary">
                  {article.tags.map((tag) => `#${tag}`).join(" ")}
                </TextSeo>
                <ViewSeo className={styles.cardFooter} row justify="between">
                  <TextSeo ink="black-secondary">
                    {format(article.publishedDate, "SHORT_MONTH")}
                  </TextSeo>
                  <Link className={styles.link} href={article.url}>
                    <TrackingButtonSeo
                      text={cr.articlesReadMoreButtonText}
                      size="small"
                      variant="outline-primary"
                      track={trackReadArticleButtonClick(
                        article.title,
                        article.publishedDate,
                        articleOrder
                      )}
                    />
                  </Link>
                </ViewSeo>
              </ViewSeo>
            </CardSeo>
          ))}
        </ViewSeo>
        <TrackingLink
          href="/articles?page=1"
          track={trackBrowseMoreArticleButtonClick}
        >
          <ButtonSeo
            className={styles.browseMoreButton}
            text={cr.articlesBrowseMoreButtonText}
            variant="outline-primary"
          />
        </TrackingLink>
      </ViewSeo>
    </Sensor>
  );
}
