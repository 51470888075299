import Link from "next/link";

import { MEETING_URL } from "@ctv/app/[locale]/_components/pre-login/constants";
import { useContentResource, useTracker } from "@ctv/core";
import { ButtonSeo, Token } from "@ctv/shared-core/src";
import { trackArrangeAMeetingButtonClick as meetingData } from "@ctv/shared/tracking/landing-page/buttonClick";

import CalendarIcon from "@traveloka/icon-kit-web/react/IcSystemCalendar16";

import styles from "./ArrangeMeetingFloatingButton.module.css";

export default function ArrangeMeetingFloatingButton() {
  const track = useTracker();
  const cr = useContentResource().CorporateRevampHomePage;

  return (
    <Link
      href={MEETING_URL}
      onClick={() => track(meetingData.event, meetingData.data)}
    >
      <ButtonSeo
        className={styles.button}
        startIcon={
          <CalendarIcon
            color={Token.color.lightPrimary}
            width={16}
            height={16}
          />
        }
        variant="floating-primary"
        text={cr.arrangeMeetingButtonText}
      />
    </Link>
  );
}
