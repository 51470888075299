import { Image, TextSeo, ViewSeo } from "@ctv/shared-core/src";
import { PropsWithChildren } from "react";
import cssStyles from "./BenefitItem.module.css";

type Props = {
  imageUrl: string;
  imageAlt?: string;
  title: string;
  subtitle: string;
};
export default function BenefitItem(props: PropsWithChildren<Props>) {
  const { children, imageAlt = "", imageUrl, title, subtitle } = props;
  return (
    <ViewSeo spacing="s" align="center" justify="center">
      <Image alt={imageAlt} src={imageUrl} width={250} height={250} />
      <ViewSeo spacing="s">
        <TextSeo variant="title-1" ink="interactive">
          {title}
        </TextSeo>
        <TextSeo
          variant="ui-baseline"
          className={cssStyles.subtitle}
          ink="black-secondary"
        >
          {subtitle}
        </TextSeo>
        {children}
      </ViewSeo>
    </ViewSeo>
  );
}
