import { PropsWithChildren } from "react";

import VisibilitySensor from "@ctv/app/[locale]/(post-login)/(management)/_shared/components/VisibilitySensor/VisibilitySensor";
import { useTracker } from "@ctv/core";

type UrsulaTrackingSensorProps = {
  event: string;
  data: {
    pageName: string;
    eventName: string;
    pageCategory: string;
    actionCategory: string;
    nonInteraction: boolean;
  };
};

export function UrsulaTrackingSensor(
  props: PropsWithChildren<UrsulaTrackingSensorProps>
) {
  const { data, event } = props;

  const track = useTracker();

  return (
    <VisibilitySensor triggerOnce onTrigger={() => track(event, data)}>
      {props.children}
    </VisibilitySensor>
  );
}
