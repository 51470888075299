import {
  useContentResource,
  useFeatureControl,
  useImageResource,
} from "@ctv/core";
import { ORS_DEPRECATION_FC_KEY } from "@ctv/core/feature-control/constants";
import { ButtonSeo, Image, TextSeo, ViewSeo } from "@ctv/shared-core/src";
import TrackingLink from "@ctv/shared/components/Tracking/TrackingLink";
import {
  trackLogInHeroButtonClick,
  trackSignUpHeroButtonClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";

import ProductBadge from "./ProductBadge";

import styles from "./Hero.module.css";

export default function Hero() {
  const {
    flightsText,
    hotelsText,
    heroSubtitle,
    title,
    loginButtonText,
    signUpButtonText,
  } = useContentResource().CorporateRevampHomePage;
  const { hero_mobile: heroImage } = useImageResource().CorporateRevampHomePage;
  const { enabled, properties } = useFeatureControl<{
    "google-form-link": string;
  }>(ORS_DEPRECATION_FC_KEY);

  return (
    <ViewSeo className={styles.container}>
      <Image
        className={styles.image}
        src={heroImage.link}
        alt={heroImage.altText}
      />
      <ViewSeo className={styles.content} spacing="m">
        <ViewSeo row spacing="xs">
          <ProductBadge
            src="/images/white-flight-icon.svg"
            text={flightsText}
          />
          <ProductBadge src="/images/white-hotel-icon.svg" text={hotelsText} />
        </ViewSeo>
        <ViewSeo spacing="xs">
          <TextSeo variant="display" ink="white-primary">
            {title}
          </TextSeo>
          <TextSeo ink="white-primary">{heroSubtitle}</TextSeo>
        </ViewSeo>
        <ViewSeo row spacing="xs">
          <TrackingLink
            className={styles.button}
            href="/login"
            track={trackLogInHeroButtonClick}
          >
            <ButtonSeo
              className={styles.button}
              variant="secondary"
              text={loginButtonText}
            />
          </TrackingLink>
          <TrackingLink
            className={styles.button}
            localized={!enabled}
            href={enabled ? properties["google-form-link"] : "/register"}
            track={trackSignUpHeroButtonClick}
          >
            <ButtonSeo
              className={styles.button}
              variant="main-cta"
              text={signUpButtonText}
            />
          </TrackingLink>
        </ViewSeo>
      </ViewSeo>
    </ViewSeo>
  );
}
