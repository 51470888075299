import React, { PropsWithChildren, useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';

type Props = {
  onTrigger(): void;
  triggerOnce?: boolean;
};

export default function VisibilitySensor(props: PropsWithChildren<Props>) {
  const { onTrigger, triggerOnce } = props;

  const [hasTriggered, setHasTriggered] = useState(false);

  function trigger(isVisible: boolean) {
    if (!isVisible) {
      return;
    }

    if (triggerOnce) {
      if (!hasTriggered) {
        onTrigger();
        setHasTriggered(true);
      }
      return;
    }

    onTrigger();
  }

  return (
    <ReactVisibilitySensor partialVisibility scrollCheck onChange={trigger}>
      {props.children}
    </ReactVisibilitySensor>
  );
}
