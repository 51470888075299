import { PropsWithChildren } from "react";

import { Image, TextSeo, ViewSeo } from "@ctv/shared-core/src";

type Props = {
  imageData: {
    link: string;
    altText: string;
  };
  title: string;
  subtitle: string;
};

export default function BenefitItem(props: PropsWithChildren<Props>) {
  const { imageData, title, subtitle, children } = props;

  return (
    <ViewSeo spacing="m" align="center">
      <Image
        src={imageData.link}
        alt={imageData.altText}
        width={250}
        height={250}
      />
      <ViewSeo spacing="s">
        <TextSeo variant="title-2" ink="interactive">
          {title}
        </TextSeo>
        <TextSeo variant="ui-small" ink="black-secondary">
          {subtitle}
        </TextSeo>
        {children}
      </ViewSeo>
    </ViewSeo>
  );
}
